import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import imgConcertHall from "../../images/concert-hall-music-republicain.jpg";
import Image from "../../components/Image";
import { Link } from "gatsby";

export default () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const handleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <section className="creative padding_bottom">
      <div className="container">
        <div className="row align-items-center justify-content-start">
          <div className="col-md-6 col-xl-7">
            <div className="creative_img">
              <Image
                className="w-100"
                src={imgConcertHall}
                alt="concert hall music republicain"
              />
            </div>
          </div>
          <div className="col-md-6 col-xl-5">
            <div className="creative_part_text">
              <Link to="/concert-hall">
                <h2>Concert Hall</h2>
              </Link>
              <p>
                Music Républicain concert hall is a multi-purpose hall that is
                designed to accommodate various musical activities. From
                ensemble, orchestra, theatre, opera, broadway show, concerts,
                seminars, all the way to competitions. This concert hall has
                been our most precious gem for over a decade. It was specially
                designed by the renowned US-based acoustic expert, Hadi Sumoro,
                to create an all-around warm acoustic sound wherever you're
                seated. Oozing Old Hollywood charm and being spacious enough to
                seat 140 people, this hall is a local favorite.
              </p>
              <a href className="btn btn-link" onClick={handleModal}>
                <i className="icon-play" /> Concert &amp; Performances
              </a>
              <ModalVideo
                channel="youtube"
                isOpen={isModalOpen}
                videoId="ky0LIncBnU8"
                onClose={handleModal}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
